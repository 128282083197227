<template>
  <div class="page">
    <div class="pageTitle">Новости</div>
    <div class="title">Рассказываем о праве</div>
    <div @click="openLink('https://www.instagram.com/izvarin_law/')" class="blogLink">
      Перейти в блог
      <img src="../assets/svg/bi_arrow-left.svg" alt=""></div>
    <div class="itemsVideo">
      <div class="item" v-for="item in instaData" @click="openLink(item.permalink)">
        <img v-if="item.media_type === 'IMAGE'" :src="item.media_url" :alt="item.caption">
        <img v-else :src="item.thumbnail_url" :alt="item.caption">
        <p v-text="caption(item.caption)"></p>
      </div>
    </div>
    <div class="title">Новости из СМИ</div>
    <div class="itemsNews">
      <div class="item" @click="openLink('https://vk.com/wall374541892_121')">
        <img src="../../public/img/news1.png" alt="">
        <p>Получение единовременной социальной выплаты для приобретения жилья.</p>
      </div>
      <div class="item" @click="openLink('https://vk.com/wall374541892_91')">
        <img src="../../public/img/news2.png" alt="">
        <p>Как заставить коммунальные службы отвечать за свои ошибки.</p>
      </div>
      <div class="item" @click="openLink('https://www.advgazeta.ru/novosti/otsutstvie-v-zakone-sroka-dlya-obespecheniya-sotrudnikov-ovd-zhilem-osporyat-v-ks/')">
        <img src="../../public/img/news3.png" alt="">
        <p>В Адвокатской газете опубликована статья относительно подготовленной жалобы в Конституционный суд.</p>
      </div>
    </div>
  </div>
</template>

<script>
import keyData from '../../public/key.json';
export default {
  name: "news",
  data() {
    return {
      instaData: [],
    }
  },
  methods: {
    caption(text){
      if(!text || text === '' ) return 'Больше информации в instagram';
      if(text.length < 60) return text;
      return text.substring(0, 60) + ' ...'
    },
    openLink(e){
      window.open(e, '_blank');
    },
    async loadInstagram(){
      let url = "https://graph.instagram.com/me/media?fields=id,media_type,caption,media_url,thumbnail_url,permalink,username,timestamp&access_token=" + keyData.name;
      fetch(url)
          .then(response => response.json())
          .then(result => {
            this.instaData.splice(0);
            result.data.forEach((el,ind)=>{
              if(ind < 8) this.instaData.push(el);
            })
          })
          .catch(error => alert("Не удалось подключиться к instagram :("));
    }
  },
  created() {
    this.loadInstagram();
    document.title = 'IzvarinLaw - Новости';
  }
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .pageTitle
    font-size: 24px
    font-weight: 600
    display: none
    margin-bottom: 30px
    +r(750)
      display: block
    +r(550)
      font-size: 20px
  .page
    width: 100%
    display: flex
    flex-direction: column
    padding: 60px 60px 20px 70px
    +r(750)
      padding: 60px 30px
  .title
    font-size: 35px
    font-weight: 500
    +r(1400)
      font-size: 30px
    +r(998)
      font-size: 22px
    +r(550)
      font-size: 18px
  .blogLink
    color: #006D5A
    font-weight: 500
    font-size: 22px
    margin: 20px 0
    padding-left: 20px
    display: flex
    align-items: center
    cursor: pointer
    img
      margin-left: 10px
    +r(998)
      font-size: 18px
    +r(550)
      font-size: 16px
      img
        width: 20px
        margin-top: 2px
    &:hover
      color: #DB6500
    span
      margin-left: 20px
  .itemsVideo
    display: flex
    flex-wrap: wrap
    margin-bottom: 50px
    width: 90%
    .item
      cursor: pointer
      margin: 10px 20px
      width: 220px
      img
        width: 100%
        border-radius: 3px
        filter: grayscale(100%)
        &:hover
          filter: none
      +r(550)
        width: 100%
        margin: 10px 0
        img
          max-width: 100%
          height: auto
      &:hover
        filter: none
        p
          opacity: 1
          color: #DB6500
      p
        color: #fff
        opacity: .5
        line-height: 1.4
  .itemsNews
    display: flex
    flex-wrap: wrap
    padding: 30px 0
    width: 100%
    .item
      cursor: pointer
      margin: 10px 20px
      width: 230px
      img
        width: 100%
      +r(550)
        margin: 10px 0
        width: 100%
        img
          width: 100%
      &:hover
        filter: none
        p
          opacity: 1
          color: #DB6500
      p
        color: #fff
        opacity: .5
        line-height: 1.5
</style>