<template>
  <div class="page">
    <div class="items">
      <div class="pageTitle">Услуги</div>
      <div class="item">
        <div class="title">Арбитражные споры</div>
        <div class="text">Подготовка документов и участие в судебных заседаниях на всех стадиях. </div>
      </div>
      <div class="item">
        <div class="title">Налоговы споры</div>
        <div class="text">
          Защита от неправомерных действий налоговых органов;<br>
          Возмещение НДС;<br>
          Взыскание необснованно уплаченных налогов.
        </div>
      </div>
      <div class="item">
        <div class="title">Исполнительное производство</div>
        <div class="text">
          Возбуждение исполнительного производства;<br>
          Обжалование действий судебных приставов.
        </div>
      </div>
      <div class="item">
        <div class="title">Авторское право</div>
        <div class="text">
          Защита авторских прав во внесудебном порядке;<br>
          Защита авторских прав в суде;<br>
          Регистрация брендов, патентов;<br>
          Подготовка судебных исков и претензий к нарушителям авторских прав;<br>
          Получение компенсаций от нарушителей авторских прав.
        </div>
      </div>
      <div class="item">
        <div class="title">Внешнеэкономическая деятельность</div>
        <div class="text">
          Разработка, анализ договоров и контрактов с иностранными компаниями. Иски и судебные споры с участием иностранных фирм. Вопросы по импорту, экспорту. Открытие и ведению бизнеса в различных странах.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "service",
  created() {
    document.title = 'IzvarinLaw - Услуги';
  }
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .pageTitle
    font-size: 24px
    font-weight: 600
    display: none
    margin-bottom: 20px
    +r(750)
      display: block
    +r(650)
      font-size: 20px
  .page
    width: 100%
    display: flex
  .items
    width: 100%
    display: flex
    flex-direction: column
    padding: 50px 30% 50px 100px
    +r(998)
      padding: 50px 30px
      width: 600px
    +r(750)
      padding: 60px 30px
      width: 100%
  .item
    width: 100%
    display: flex
    flex-direction: column
    font-weight: 500
    font-size: 34px
    margin: 0 50px 50px 0
    padding: 0
    +r(1400)
      margin: 0 50px 30px 0
    +r(998)
      margin: 0 50px 20px 0
  .title
    position: relative
    font-weight: 500
    font-size: 20px
    margin: 0 0 30px 0
    line-height: 24px
    padding: 0
    color: #fff
    +r(998)
      font-size: 18px
    +r(650)
      font-size: 16px
    &:before
      content: ''
      position: absolute
      bottom: -18px
      left: 0
      width: 74px
      height: 3px
      background: #006D5A
  .text
    line-height: 32px
    font-weight: 500
    font-size: 18px
    width: 100%
    color: #DADADA
    opacity: .4
    +r(1400)
      line-height: 24px
      font-size: 16px
    +r(998)
      font-size: 16px
      line-height: 26px
    +r(650)
      font-size: 16px
</style>